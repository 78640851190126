<template>
  <div class="nobleLv6">
    <div class="nobleImg">
      <img src="../../assets/imgs/noble/lv6.png" alt="">
      <span>Privileges {{hasAuthArr.length}} / {{authLen}}</span>
    </div>
    <div class="displayFun">
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <span v-if="index<=19" @click="a(index)">
            <img :src="item.img" alt="">
            <span class="helpful">{{item.name}}</span>
          </span>
          <span v-else>
            <img :src="item.img" alt="">
            <span>{{item.name}}</span>
          </span>
        </li>
      </ul>
    </div>
    <van-popup v-model="show">
      <div class="lunbo">
        <div class="dis">
          <van-swipe class="my-swipe" indicator-color="white" :initial-swipe="lunboIndex">
            <template v-for="(item,index) in list">
              <van-swipe-item :key="index" v-if="item.gift!==undefined">
                <div class="content">
                  <span v-if="item.showBg===true" class="showBg">
                    <img :src="item.gift" alt="">
                  </span>
                  <span class="noBg" v-else> <img :src="item.gift" alt=""></span>
                  <span class="name" v-if="item.title!='' && item.title!=null && item.title!=undefined">{{item.title}}</span>
                  <span class="name" v-else>{{item.name}}</span>
                  <span class="text">{{item.content}}</span>
                </div>
              </van-swipe-item>
            </template>
          </van-swipe>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import SVGA from 'svgaplayerweb';
export default {
  name: 'nobleLv6',
  data () {
    return {
      list: [
        {
          img: require('../../assets/imgs/noble/g1.png'),
          name: 'Emperor ICON',
          gift: require('../../assets/imgs/noble/lv6/lv6_gift1.png'),
          showBg: true,
          content: 'It will be displayed on: your mini data card, chat in the room, friends list, friends request, leaderboards...'
        },
        {
          img: require('../../assets/imgs/noble/g2.png'),
          name: 'Mic Frame',
          gift: require('../../assets/imgs/noble/lv6/lv6_gift2.png'),
          showBg: true,
          content: 'You can use this frame. You can manage it in "My Decorations"'
        },
        {
          img: require('../../assets/imgs/noble/g3.png'),
          name: 'Higher Ranking',
          gift: require('../../assets/imgs/noble/g3.png'),
          showBg: false,
          content: 'Higher ranking in online user list of  room'
        },
        {
          img: require('../../assets/imgs/noble/g4.png'),
          name: 'Room Owner Rewards',
          gift: require('../../assets/imgs/noble/g4.png'),
          title: 'Room Owner Rewards', //点击详情标题与原页面展示标题不一致时这里填详情标题，如果一致就不用填
          showBg: false,
          content: 'As room owner, you can get 5% commission from gifts sent in your room'
        },
        {
          img: require('../../assets/imgs/noble/g5.png'),
          name: 'Entrance Effect',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift3.png'),
          content: 'You can show the entrance effect when entering into any room'
        },
        {
          img: require('../../assets/imgs/noble/g6.png'),
          name: 'Room Lock',
          showBg: false,
          gift: require('../../assets/imgs/noble/g6.png'),
          content: 'Lock your room, others can enter only if they have the password'
        },
        {
          img: require('../../assets/imgs/noble/g17.png'),
          name: 'Live Room',
          showBg: false,
          gift: require('../../assets/imgs/noble/g17.png'),
          content: 'Create live room'
        },
        {
          img: require('../../assets/imgs/noble/g7.png'),
          name: 'High Speed Upgrade',
          showBg: false,
          gift: require('../../assets/imgs/noble/g7.png'),
          content: '1.5 time faster experience'
        },
        {
          img: require('../../assets/imgs/noble/g8.png'),
          name: 'Colorful Message',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift4.png'),
          content: 'Your messages will be colourful'
        },
        {
          id: 9,
          img: require('../../assets/imgs/noble/g21.png'),
          name: 'GIF Pictures',
          showBg: true,
          gift: require('../../assets/imgs/noble/g21.png'),
          content: 'Upload GIF pictures for avatar, user profile theme, room theme, hashtag cover...'
        },
        {
          img: require('../../assets/imgs/noble/g16.png'),
          name: "Friend's Rooms",
          showBg: false,
          gift: require('../../assets/imgs/noble/g16.png'),
          content: 'You can access friends’s rooms in "Mine-Friends" page easily'
        },
        {
          img: require('../../assets/imgs/noble/g9.png'),
          name: 'Ride',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift5.png'),
          content: 'You can show the ride when entering any room. You can manage it in "My Decorations"'
        },
        {
          img: require('../../assets/imgs/noble/g10.png'),
          name: 'Hide Country',
          showBg: false,
          gift: require('../../assets/imgs/noble/g10.png'),
          content: 'Your country will be shown as "unknown country" in your profile'
        },
        {
          img: require('../../assets/imgs/noble/g15.png'),
          name: 'Aristocracy Sign-in',
          gift: require('../../assets/imgs/noble/g15.png'),
          title: 'Sign-in of Duke',
          content: 'Good amount of daily sign-in coins'
        },
        {
          img: require('../../assets/imgs/noble/g11.png'),
          name: 'Exclusive Gift',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift6.png'),
          content: 'Only Emperor can send this gift'
        },
        {
          img: require('../../assets/imgs/noble/g12.png'),
          name: 'Upload Room Themes',
          showBg: false,
          gift: require('../../assets/imgs/noble/g12.png'),
          content: 'Upload room themes, and can use all themes freely'
        },
        {
          img: require('../../assets/imgs/noble/g13.png'),
          name: 'Anti-kick',
          gift: require('../../assets/imgs/noble/g13.png'),
          content: 'No one can kick you out of room'
        },
        {
          img: require('../../assets/imgs/noble/g14.png'),
          name: 'Anti-ban of Text',
          gift: require('../../assets/imgs/noble/g14.png'),
          content: 'No one can ban you from text chat in the room'
        },
        {
          img: require('../../assets/imgs/noble/g18.png'),
          name: 'Unique Mic',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift7.png'),
          content: 'Unique mic design for Emperor'
        },
        {
          img: require('../../assets/imgs/noble/g19.png'),
          name: 'Unique User Name',
          showBg: true,
          gift: require('../../assets/imgs/noble/lv6/lv6_gift8.png'),
          content: 'Unique user name to be shown in room messages, mic, user profile, room online list'
        },
        {
          img: require('../../assets/imgs/noble/g20.png'),
          name: 'Hide Room',
          gift: require('../../assets/imgs/noble/g20.png'),
          content: "After hiding, the room will hide in room page and all broadcastings of the room won't show up"
        }

      ],
      show: false,
      lunboIndex: 0,
      authLen: 0,      
      hasAuthArr: []
    }
  },
  created () {
    this.authLen = this.list.length
    this.list.forEach(val => {if(val.gift && val.gift !== undefined) this.hasAuthArr.push(val)});
  },
  methods: {
    a (index) {
      this.show = true
      this.lunboIndex = index
    }
  }
}
</script>

<style lang="scss">
.nobleLv6 {
  #svgaBox {
    width: 20vw;
    height: 20vw;
  }
  .nobleImg {
    color: #333;
    font-size: 14px;
    margin-top: 5vw;
    span {
      display: block;
      font-weight: bold;
    }
    img {
      width: 40vw;
    }
  }
  .displayFun {
    padding-bottom: 25vw;
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 90vw;
      margin: auto;
      margin-top: 4vw;
      li {
        width: 30vw;
        padding: 2vw 0;
        img {
          width: 8vw;
        }
        span {
          color: #959aa6;
          font-size: 12px;
          display: block;
          width: 20vw;
          margin: auto;
          margin-top: 1vw;
        }
        .helpful {
          color: #b08b55;
        }
      }
    }
  }
  .van-popup {
    background: none;
  }
  .van-popup--center {
    height: 80vw;
  }
  .lunbo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .dis {
      background: #fff;
      width: 80vw;
      border-radius: 4vw;
      position: relative;
    }

    .van-swipe {
      position: initial;
    }

    .my-swipe .van-swipe-item {
      color: #333;
      text-align: center;
      border-radius: 4vw;
    }
    .van-swipe__indicators {
      bottom: -5vw;
    }
    .van-swipe__indicator {
      width: 5px;
      height: 5px;
    }
    .van-swipe__indicator--active {
      width: 13px;
      border-radius: 5px;
    }
    .content {
      .showBg {
        width: 65vw;
        background: url("../../assets/imgs/noble/gift_bg.png") no-repeat;
        background-size: 100%;
        margin: auto;
        margin-top: 7vw;
        img {
          height: 16vw;
          padding: 1vw 0;
        }
      }
      .noBg {
        img {
          height: 18vw;
          margin-top: 8vw;
        }
      }
      span {
        display: block;
      }
      .name {
        color: #333333;
        font-size: 18px;
        padding: 4vw;
        font-weight: bold;
      }
      .text {
        color: #6d6f73;
        font-size: 15px;
        width: 70vw;
        margin: auto;
        padding-bottom: 5vw;
      }
    }
  }
}
</style>